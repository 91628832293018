import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="container" style={{ background: "#141b2d" }}>
      <div className="p-5 shadow text-center mt-5">
        <p
          style={{
            color: "#f8f9fa",
            fontStyle: "italic",
            fontSize: "5rem",
          }}
        >
          {" "}
          404{" "}
        </p>
        <p style={{ color: "#198758", fontSize: "2rem" }}>
          Oops! The page you're looking for is not here.
        </p>
        <Link to={"/"} className="btn btn-lg btn-warning">
          Go Back
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;
