import React from "react";

export const Contact = () => {
  return (
    <div className="container">
      <h1 className="text-3xl sm:text-5xl font-bold mb-4 text-center py-5">
        Contact Us:
      </h1>
      <div className="content p-5 my-10">
        {" "}
        <span className="blockquote">
          We are Dubai based company and providing best logistics and courier
          services in UAE, GCC, and World Wide
        </span>
        <span className="divider" />
        <p>
          We offer tailored delivery solutions designed to meet the unique needs
          of e-commerce businesses. Our efficient, cost-effective, and scalable
          services ensure that your products reach your customers on time, every
          time. Whether you're shipping locally or globally, we provide flexible
          options that enhance customer satisfaction and streamline your
          logistics. With real-time tracking, reliable support, and seamless
          integration with your e-commerce platform, we help you scale your
          business with ease. Partner with us for hassle-free delivery and focus
          on growing your brand!
        </p>
      </div>
    </div>
  );
};
