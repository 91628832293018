import React from "react";

export const AboutUs = () => {
  return (
    <article className="prose prose-neutral container mx-auto">
      <h1 className="font-bold mb-4 text-center py-5">AboutUs</h1>

      <div className="py-5">
        <blockquote className="blockquote">
          We are Dubai based company and providing best logistics and courier
          services in UAE, GCC, and World Wide
        </blockquote>
        <h3>E-Commerce Delivery:</h3>
        <p>
          At Tricornerx Delivery Services we offer tailored delivery solutions
          designed to meet the unique needs of e-commerce businesses. Our
          efficient, cost-effective, and scalable services ensure that your
          products reach your customers on time, every time. Whether you're
          shipping locally or globally, we provide flexible options that enhance
          customer satisfaction and streamline your logistics. With real-time
          tracking, reliable support, and seamless integration with your
          e-commerce platform, we help you scale your business with ease.
          Partner with us for hassle-free delivery and focus on growing your
          brand!
        </p>
        <br />
        <h3>Customized Delivery:</h3>
        <p>Our customized delivery includes services include:</p>
        <ul>
          <li>
            {" "}
            <h4>Flexible Delivery Options:</h4> Choose from same-day, next-day,
            or scheduled deliveries that suit your business model and customer
            expectations.
          </li>
          <li>
            {" "}
            <h4>Branded Packaging & White-Label Services:</h4> Keep your brand
            front and center with personalized packaging solutions that make
            every delivery feel special.
          </li>
          <li>
            <h4>Multi-Channel Integration:</h4> Seamlessly integrate with your
            e-commerce platform (Shopify, WooCommerce, etc.) for a smooth
            order-to-delivery experience.
          </li>
          <li>
            <h4>Cost-Effective Solutions:</h4> We assess your delivery volume
            and routes to provide the most efficient and cost-effective shipping
            options.
          </li>
          <li>
            <h4>Real-Time Tracking:</h4> Keep your customers in the loop with
            accurate, real-time tracking, ensuring transparency and trust.
          </li>
          <li>
            <h4>Global & Local Delivery Capabilities:</h4> Expand your reach
            with both local and international shipping options tailored to your
            business's growth. No matter the complexity or scale of your
            operations, we are here to deliver customized solutions that match
            your vision. Let us handle your logistics so you can focus on
            scaling your e-commerce business.
          </li>
        </ul>
      </div>
    </article>
  );
};
