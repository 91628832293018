import React from "react";
import Home from "../pages/home";
import PageNotFound from "../pages/not-found";
import Tracker from "../pages/tracker";
import App from "../App";
import { AboutUs } from "../pages/about-us";
import { Contact } from "../pages/contact";

export default [
  {
    path: "/",
    element: <App />,

    children: [
      { index: true, path: "/", element: <Home /> },
      { index: true, path: "/home", element: <Home /> },
      { index: true, path: "/about-us", element: <AboutUs /> },
      { index: true, path: "/contact", element: <Contact /> },
      { index: true, path: "/track", element: <Tracker /> },
    ],
    errorElement: <PageNotFound />,
  },
];
